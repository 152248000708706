<template>
  <svg
    xmlns="http://www.w3.org/2000/svg"
    :viewBox="viewBox"
    :aria-labelledby="iconName"
    role="presentation"
  >
    <title
      :id="iconName"
    >{{ iconName }} icon</title>
    <g :fill="iconColor">
      <slot />
    </g>
    <defs>
      <slot name="defs" />
    </defs>
  </svg>
</template>

<script>
  export default {
    props: {
      iconName: {
        type: String,
        default: 'box'
      },
      width: {
        type: [Number, String],
        default: 18
      },
      height: {
        type: [Number, String],
        default: 18
      },
      iconColor: {
        type: String,
        default: 'currentColor'
      }
    },
    computed: {
      viewBox: function () {
        return "0 0 "+this.width+" "+this.height
      }
    }
  }
</script>